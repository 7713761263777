// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
    authDomain: 'sethi-supplier-user-reviews.firebaseapp.com',
    projectId: 'sethi-supplier-user-reviews',
    storageBucket: 'sethi-supplier-user-reviews.appspot.com',
    messagingSenderId: '1026066472875',
    appId: '1:1026066472875:web:f423eb25a1a7bd11d87cee',
    measurementId: 'G-S8FJW06X3Q',
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
getAnalytics(firebase);
